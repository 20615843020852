@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");
@import "normalize.css";

.footer {
  color: var(--main-color);
  white-space: nowrap;
}

a {
  text-decoration: none;
  color: inherit;
}

.global-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: absolute;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.matrix-container {
  display: flex;
  flex-direction: column;
}

.start-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.audio-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* MOBILE (phones, 37.5em (600px) and down) */
@media only screen and (max-width: 37.5em) {
  .global-container {
    justify-content: flex-end;
  }

  .nav-bar {
    display: flex;
    justify-content: center;
    padding: 0rem 0rem 1rem;
  }

  .start-row {
    margin: 3rem 0;
  }

  .matrix-container {
    width: 70vw;
    height: 18rem;
    margin-top: 2rem;
  }

  .footer {
    display: none;
  }
}

/* (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) and (orientation: portrait) {
  .nav-bar {
    display: flex;
    justify-content: flex-end;
    padding: 3rem;
  }

  .matrix-container {
    width: 30rem;
    height: 20rem;
    margin-top: 3rem;
  }

  .start-row {
    margin: 5rem 0;
  }

  .footer {
    margin: 5vh 4rem;
    font-size: 0.75rem;
  }

  .logo-timer-container {
    width: 75%;
  }
}

/* (portrait tablets and large phones, 37.5em (600px) and up) LANDSCAPE */
@media only screen and (min-width: 37.5em) and (orientation: landscape) {
  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-bar {
    display: flex;
    justify-content: flex-end;
    padding: 3rem;
  }

  .white-noise-logo {
    font-size: 1rem;
  }

  .footer {
    margin: 3rem 3rem;
    font-size: 0.75rem;
  }

  .logo-timer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3rem 5rem;
  }

  .left-empty-space {
    margin: 5vh 0;
  }

  .larger-matrix-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 22vw;
    margin-top: 10vh;
  }

  .matrix-container {
    width: 17rem;
    height: 17rem;
  }

  .start-row {
    margin: 4rem 0;
  }

  .volume-slider {
    position: relative;
    left: -6rem;
  }
}

/* Medium devices (landscape tablets, 48em (768px) and up) */
@media only screen and (min-width: 48em) and (orientation: portrait) {
  .nav-bar {
    display: flex;
    justify-content: flex-end;
    padding: 3rem;
  }

  .matrix-container {
    width: 60vw;
    height: 20rem;
    margin-top: 10vh;
  }

  .start-row {
    margin: 8vh 0;
  }

  .footer {
    margin: 5vh 8vw;
    font-size: 0.75rem;
  }

  .logo-timer-container {
    width: 75%;
  }
}

/* Large devices (laptops/desktops, 62em (992px) and up) */
@media only screen and (min-width: 62em) {
}

/* Extra large devices (large laptops and desktops, 75em (1200px) and up) */
@media only screen and (min-width: 75em) {
  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-bar {
    display: flex;
    justify-content: flex-end;
    padding: 3rem;
  }

  .white-noise-logo {
    font-size: 1rem;
  }

  .footer {
    margin: 10vh 7rem;
    font-size: 1rem;
  }

  .logo-timer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 4vh 9rem;
  }

  .left-empty-space {
    margin: 5vh 0;
  }

  .larger-matrix-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 22vw;
    margin-top: 10vh;
  }

  .matrix-container {
    width: 20rem;
    height: 20rem;
  }

  .start-row {
    margin: 5rem 0;
  }

  .volume-slider {
    position: relative;
    left: -6rem;
  }
}
