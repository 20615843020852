.restart-box {
  color: var(--main-color);
  width: 2.2rem;
  background-color: transparent;
  border: none;
  line-height: normal;
  padding: 0rem;
}

/* Extra small devices (phones, 37.5em (600px) and down) */
@media only screen and (max-width: 37.5em) {
  .restart-box {
    padding-top: 0.2rem;
    padding: 0;
  }

  .restart-button {
    font-size: 2.2rem;
  }
}

/* Small devices (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) and (orientation: portrait) {
}

/* Small devices (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) {
  .restart-box {
    padding-top: 0.2rem;
  }

  .restart-button {
    font-size: 2.2rem;
  }
}

/* Medium devices (landscape tablets, 48em (768px) and up) */
@media only screen and (min-width: 48em) {
}

/* Large devices (laptops/desktops, 62em (992px) and up) */
@media only screen and (min-width: 62em) {
}

/* Extra large devices (large laptops and desktops, 75em (1200px) and up) */
@media only screen and (min-width: 75em) {
  .restart-button:hover {
    font-size: 2.3rem;
    transition: font-size 0.15s ease-in-out 0s;
  }
}
