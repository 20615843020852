.timer {
  color: var(--main-color);
  font-family: "Roboto Flex", sans-serif;
  line-height: normal;
  margin-top: 4rem;
  font-weight: 600;
}

/* Extra small devices (phones, 37.5em (600px) and down) */
@media only screen and (max-width: 37.5em) {
  .timer {
    text-align: center;
    font-size: 6rem;
    margin-top: 3rem;
  }
}

/* Small devices (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) and (orientation: portrait) {
  .timer {
    text-align: center;
    font-size: 7rem;
    margin-top: 5rem;
  }
}

/* (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) and (orientation: landscape) {
  .timer {
    text-align: left;
    font-size: 5rem;
    margin-top: 4rem;
  }
}

/* Medium devices (landscape tablets, 48em (768px) and up) */
@media only screen and (min-width: 48em) {
}

/* Large devices (laptops/desktops, 62em (992px) and up) */
@media only screen and (min-width: 62em) {
}

/* Extra large devices (large laptops and desktops, 75em (1200px) and up) */
@media only screen and (min-width: 75em) {
  .timer {
    text-align: left;
    font-size: 6rem;
  }
}
