:root {
  --main-color: #197f31;
  --background-color: #ffffff;
  --text-color: #000000;
  --transition-duration: 0.3s; /* Define a variable for transition duration */
}

[data-theme="dark"] {
  --main-color: #71b695;
  --background-color: #242424;
  --text-color: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color var(--transition-duration),
    color var(--transition-duration); /* Apply transitions */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  transition: color var(--transition-duration); /* Apply transitions */
}
