.start-button,
.start-clicked {
  font-family: "Montserrat", sans-serif;
  width: 6rem;
  height: 3rem;
  background-color: transparent;
  border: transparent;
  color: var(--main-color);
  white-space: nowrap;
  font-weight: 700;
  padding: 0;
}

/* Extra small devices (phones, 37.5em (600px) and down) */
@media only screen and (max-width: 37.5em) {
  .start-button,
  .start-clicked {
    font-size: 2rem;
  }
}

/* Small devices (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) and (orientation: portrait) {
}

/* Small devices (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) {
  .start-button,
  .start-clicked {
    font-size: 2rem;
  }
}

/* Medium devices (landscape tablets, 48em (768px) and up) */
@media only screen and (min-width: 48em) {
}

/* Large devices (laptops/desktops, 62em (992px) and up) */
@media only screen and (min-width: 62em) {
  .start-button,
  .start-clicked {
    font-size: 2.2rem;
  }

  .start-button:hover,
  .start-clicked:hover {
    font-size: 2.05rem;
    transition: font-size 0.15s ease-in-out 0s;
  }
}

/* Extra large devices (large laptops and desktops, 75em (1200px) and up) */
@media only screen and (min-width: 75em) {
}
