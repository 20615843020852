.button,
.button-selected {
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: transparent;
  color: var(--main-color);
  white-space: nowrap;
  font-weight: 800;
  width: 2.4rem;
  height: 2rem;
  padding: 0rem;
}

/* Extra small devices (phones, 37.5em (600px) and down) */
@media only screen and (max-width: 37.5em) {
  .button,
  .button-selected {
    font-size: 1.7rem;
    padding: 0; /* mobile version acting weird so have to force this */
  }
}

/* Small devices (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) and (orientation: portrait) {
}

/* Small devices (portrait tablets and large phones, 37.5em (600px) and up) */
@media only screen and (min-width: 37.5em) {
  .button,
  .button-selected {
    font-size: 1.7rem;
  }
}

/* Medium devices (landscape tablets, 48em (768px) and up) */
@media only screen and (min-width: 48em) {
}

/* Large devices (laptops/desktops, 62em (992px) and up) */
@media only screen and (min-width: 62em) {
  .button,
  .button-selected {
    font-size: 2rem;
  }
}

/* Extra large devices (large laptops and desktops, 75em (1200px) and up) */
@media only screen and (min-width: 75em) {
  .button:hover {
    font-size: 1.8rem;
    transition: font-size 0.15s ease-in-out 0s;
  }
}
